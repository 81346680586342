import React, {Component} from 'react';
import logo from "../../miysis_logo.jpg";
import { NavLink } from 'react-router-dom';


const position = {
    relative: {
        position: 'relative'
    },
    fixed: {
        position: 'fixed'
    }
}


class NavBar extends Component {
    state = {
        isOpen: false,
        isClosing: false,
        isFixed: false
    }

    myScript = () => {

        if (window.pageYOffset >= 75) {
            this.setState({isFixed: true})
        } else {
            this.setState({isFixed: false})
        }
    }

    componentDidMount = () => {
        window.addEventListener("scroll", this.myScript);
    }

    close = () => {
            this.setState({isOpen: false})
    }

    toggle = () => {
        this.setState({isOpen: !this.state.isOpen})
    }

    render() {
        const { isOpen, isFixed } = this.state
        return (
            <div style={isFixed ? position.fixed : position.relative } className="navbar_container">
                <div className="logo_container">
                    <NavLink className="menu_item" to="/">
                        <img src={logo} alt="Miysis 3D Studio logo" />
                    </NavLink>
                </div>

                <div className="menu_container">
                    <div className="desktop_container">
                        <NavLink to="/" >Home</NavLink>
                        <NavLink to="/about" >Miysis</NavLink>
                        <NavLink to="/why-we-need" >Why we need Epic Games</NavLink>
                        <NavLink to="/project-1" >Project 1</NavLink>
                        <NavLink to="/project-2" >Project 2</NavLink>
                        <NavLink to="/contact">Contact</NavLink>
                        <a target="_blank" rel="noopener noreferrer" href="https://dev.miysis.be/">
                            WIP Miysis Website
                            <i className="fas fa-external-link-alt" />
                        </a>
                        <a href="Miysis_Epic_Games_Mega_Grants_Candidature.pdf" download >PDF Download <i className="far fa-file-pdf" /></a>
                    </div>

                    <button className="btn_burger" onClick={this.toggle}>
                        <div id="nav-icon3" className={isOpen ? 'open' : null } >
                            <span/>
                            <span/>
                            <span/>
                            <span/>
                        </div>
                    </button>
                    { isOpen &&
                        <div className="burger_container opening" >
                            <ul>
                                <li>
                                    <NavLink onClick={this.close} to="/" >Home</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.close} to="/about" >Miysis</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.close} to="/why-we-need" >Why we need Epic Games</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.close} to="/project-1" >Project 1</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.close} to="/project-2" >Project 2</NavLink>
                                </li>
                                <li>
                                    <NavLink onClick={this.close} to="/contact" >Contact</NavLink>
                                </li>
                                <li>
                                    <a target="_blank"  href="https://dev.miysis.be/">
                                         WIP Miysis Website
                                    </a>
                                </li>
                                <li>
                                    <a href="Miysis_Epic_Games_Mega_Grants_Candidature.pdf" download >PDF Download <i className="far fa-file-pdf" /></a>
                                </li>
                            </ul>
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export default NavBar;