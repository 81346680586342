import React, { Component } from 'react'
import logo from "../../miysis_logo.jpg";

export default class Login extends Component {
    state = {
        isLog: false,
        passVisible: false,
        password: '',
        errorMsg: false,
        pageTransition: false
    }
    
    togglePassVisibility = () => {
        this.setState(({passVisible}) => ({passVisible: !passVisible}))
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const { password } = this.state
        const value= {...this.state}
        delete value.passVisible
        if(password === '') {
            this.setState({ errorMsg: true })
        } else {
            this.props.onLogin(password)
        }
    }

    handlePasswordChange = ({ target: {value: password} }) => {
        this.setState({ password })
    }

    render() {
        const { passVisible, password, isLog, pageTransition, errorMsg } = this.state
        return (
            <>
            { !isLog &&
                <div className={`login_page ${ pageTransition ? 'leaving' : ''}`} >
                    <div className="login_container">
                        <div className="login_header">
                            <img src={logo} alt="Miysis 3D Studio logo" width="50%" />
                        </div>
                        <p className="login_subtitle">Please enter the password to the form below to enter the website</p>
                        <div className={`login_input-message ${errorMsg ? 'error' : ''}`}>The password is incorrect</div>
                        <form>
                            <label htmlFor="password">
                                <div className="login_input" >
                                    <i className="fas fa-key"/>
                                    <input
                                        placeholder="Password"
                                        autoComplete="New Pasword"
                                        type={passVisible ? "text" : "password" }
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={this.handlePasswordChange}
                                    />
                                    <div className="login_input-visible" onClick={this.togglePassVisibility}>
                                        { passVisible ? <i className="fas fa-eye"/> : <i className="fas fa-eye-slash"/> }
                                    </div>
                                </div>
                            </label>

                            <input className="btn_cta color" style={{margin: '0'}} type="submit" onClick={this.handleSubmit} value="Enter" />
                        </form>
                        <div className="login_infos">
                            <p>
                                <a href="https://www.miysis.be" target="_blank" rel="noopener noreferrer" >www.miysis.be</a>
                                - info@miysis.be
                                - +32 42 40 38 40
                            </p>
                            <p>
                                Château de Voroux-Lez-Liers,
                                Rue de la Renaissance 1,<br />
                                4451 Voroux-Lez-Liers, Belgium
                            </p>
                        </div>
                    </div>
                    
                </div>
            }
            </>
        )
    }
}
