import React, { Component, Suspense, lazy } from 'react';
import './scss/App.scss';
import NavBar from './components/layout/NavBar';
import { Switch, Route } from 'react-router';
import NotFound404 from './containers/App/404';
import CookieBanner from './components/CookieBanner';
import Login from './containers/Login';
import axios from 'axios';
import pass from './medias/assets/password.txt';
import HmacSHA256 from 'crypto-js/hmac-sha256';

const Home = lazy(() => import('./containers/Home'));
const About = lazy(() => import('./containers/About'));
const PixelStreaming = lazy(() => import('./containers/PixelStreaming'));
const Architecture = lazy(() => import('./containers/Architecture'));
const Why = lazy(() => import('./containers/Why'));
const Contact = lazy(() => import('./containers/Contact'));


class App extends Component {
    state = {
      isLogged: false
    }

    handleLogin = password => {
      axios.get(pass)
        .then(response => {
          const encoded = HmacSHA256(password, '7d792cf6a48ebd6eb9c5c33a1e3afac250940274').toString()
          if (response.data === encoded) {
            this.setState({isLogged: true})
          }
        })
    }

    render() {
        const { isLogged } = this.state
        return (
          <div className="App App-container">
              { !isLogged ?
                  <Login onLogin={this.handleLogin} />
                :
                <>
                  <Suspense fallback={<p/>}>
                    <NavBar />
                  </Suspense>

                  <Suspense fallback={<div id="preloader" />}>
                      <Switch>
                          <Route key="home" exact path='/' component={Home}/>
                          <Route key="about" exact path='/about' component={About}/>
                          <Route key="project1" exact path='/project-1' component={PixelStreaming}/>
                          <Route key="project2" exact path='/project-2' component={Architecture}/>
                          <Route key="whyWeNeed" exact path='/why-we-need' component={Why}/>
                          <Route key="contact" exact path='/contact' component={Contact}/>
                          <Route path="*" component={NotFound404} status={404}/>
                      </Switch>
                      <CookieBanner />
                  </Suspense>
                </>
              }
              
          </div>
    );
  }
}

export default App;
